<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('new_register')+' / '+$t('documents')" :isFilter="false"></Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('new_register')+' / '+$t('documents')" :isFilter="false"></HeaderMobile>
            </template>
            <div class="row data-form">
                <div class="col-12">
                    <div class="mb-4 d-flex justify-content-end">
                        <h6>{{ $t('document_due_date') }}</h6>
                    </div>

                </div>
                <div class="col-12 col-md-12">
                    <div v-for="(item,index) in documents" v-bind:key="index">
                        <b-row no-gutters class="file-upload-box mb-1">
                            <b-col sm="12" md="5" lg="4">
                                <div class="filename d-flex" :class="{'active':item.uuid}">
                                    <span class="flex-grow-1">{{ item.name }}</span>
                                </div>
                            </b-col>
                            <b-col sm="12" md="5" lg="6">
                                <b-input-group>
                                    <b-form-file
                                        :ref="index"
                                        v-model="files[index]"
                                        :placeholder="item.uuid!=null?item.name:$t('select_file')+'...'"
                                        @change="upload(index)"
                                    >
                                        <b-form-file id="file-small"></b-form-file>
                                    </b-form-file>
                                    <b-input-group-append>
                                        <b-button variant="outline-secondary" class="btn-40 download"
                                                  :v-b-popover.hover.right="$t('download')"
                                                  v-if="item.uuid"
                                                  @click="download(item.uuid)">
                                            <i class="ri-download-2-line"></i>
                                        </b-button>
                                        <b-button variant="danger" class="btn-40"
                                                  :v-b-popover.hover.right="$t('delete')"
                                                  v-if="item.uuid"
                                                  @click="remove(item.uuid)">
                                            <i class="ri-delete-bin-7-line"></i>
                                        </b-button>
                                        <b-button variant="outline-secondary" class="btn-40"
                                                  :v-b-popover.hover.right="$t('upload')"
                                                  v-if="!item.uuid">
                                            <i class="ri-add-fill"></i>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                            <b-col sm="12" md="2" lg="2">
                                <b-form-group v-if="!item.uuid">
                                    <select-date
                                        v-model="dates[index]"></select-date>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                    <b-form-group>
                        <b-button variant="primary" @click="sendForm">{{$t('save')}}</b-button>
                    </b-form-group>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
    import Header from '@/layouts/AppLayout/Header';
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile';

    import AppLayout from '@/layouts/AppLayout'
    import {ValidationObserver, ValidationProvider} from 'vee-validate';
    import RegistrationService from '@/services/RegistrationService';

    export default {
        components: {
            AppLayout,
            ValidationObserver,
            ValidationProvider,
            Header,
            HeaderMobile
        },
        metaInfo() {
            return {
                title: this.$t('new_register')+' / '+this.$t('documents')
            }
        },
        data() {
            return {
                documents: null,
                files: [],
                dates: []
            }
        },
        methods: {
            getDocuments(){
                RegistrationService.getDocuments(this.$route.params.id)
                                   .then(response => {
                                       this.documents = response.data.data
                                   })
            },

            upload(index){
                if (this.checkPermission("registration_document")) {
                    setTimeout(() => {
                        if (this.documents[index]) {
                            let formData = new FormData()
                            formData.append('document_id', this.documents[index].id)
                            formData.append('file', this.files[index])
                            RegistrationService.uploadDocuments(this.$route.params.id, formData).then(response => {
                                this.getDocuments()
                                this.$toast.success(this.$t('api.' + response.data.message));
                            }).catch(e => {
                                if (e.status == 422) {
                                    this.$toast.error(e.data.errors.file[0]);
                                }
                                if (e.status == 406) {
                                    this.$toast.error(this.$t('api.' + e.data.message));
                                }
                            }).finally(() => {
                                this.files[index] = null;
                            });
                        }
                    }, 2000)
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            download(uuid){
                if (this.checkPermission("registration_documentdownload")) {
                    RegistrationService.downloadDocuments({uuid: uuid}).then(response => {
                        const blob = new Blob([response.data], {type: response.headers['content-type']})
                        const objectUrl = window.URL.createObjectURL(blob)
                        window.open(objectUrl)
                    }).catch(e => {
                        if (e.status == 422) {
                            this.$toast.error(e.data.errors.file[0]);
                        }
                        if (e.status == 406) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            remove(uuid){
                if (this.checkPermission("registration_documentdelete")) {
                    RegistrationService.deleteDocuments(uuid).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.getDocuments()
                    }).catch(e => {
                        if (e.status == 422) {
                            this.$toast.error(e.data.errors.file[0]);
                        }
                        if (e.status == 406) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    })
                }else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            sendForm(){
                const missingDocuments = []
                this.documents.forEach((item, index) => {
                    if(item.uuid == null && this.dates[index]){
                        missingDocuments.push({
                            document_type_id: item.id,
                            document_due_date: this.dates[index]
                        })
                    }
                })

                RegistrationService.show(this.$route.params.id).then(response => {
                    let data = response.data.data
                    let exData = {}
                    if(this.isInterStudent(data.type) == true){
                        exData = {
                            'passport_number' : data.passport_number,
                            'passport_country_id' : data.passport_country_id,
                            'nationality_code' : data.nationality_code,
                            'foreign_document_type' : data.foreign_document_type,
                            'foreign_document_number' : data.foreign_document_number
                        }
                    } else {
                        exData = {
                            'mother_name' : data.mother_name,
                            'nationality_code' : data.nationality_code,
                            'birthdate' : data.birthdate,
                            'birthplace': data.birthplace
                        }
                    }
                    let exMiss = {}
                    if(missingDocuments.length > 0){
                        exMiss = {'missing_documents': missingDocuments}
                    }

                    const formData = {
                        'semester_id' : data.semester_id,
                        'type' : data.type,
                        'program_code' : data.program_code,
                        'scholarship_rate' : data.scholarship_rate,
                        'name' : data.name,
                        'surname' : data.surname,
                        'father_name' : data.father_name,
                        'gender' : data.gender,
                        'address' : data.address,
                        'mobile_tel' : data.mobile_tel,
                        ...exData,
                        ...exMiss

                    }

                    RegistrationService.update(this.$route.params.id, formData)
                                       .then(response => {
                                           this.$toast.success(this.$t('api.' + response.data.message));
                                           this.$router.push('/registrations/index')
                                           //this.$router.push('/registrations/new/register/' + this.$router.params.id + '/summary')
                                       })
                })
            },

            isInterStudent(value){
                if(value == 203 || value == 232 || value == 233 || value == 234 || value == 235){
                    return true
                } else {
                    return false
                }
            }
        },



        created() {
            this.getDocuments()
        }
    };
</script>

